import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class IdiomaService {

  constructor(private translateService: TranslateService) { }

  setIdioma(idioma: string): void {
    this.translateService.use(idioma);
    localStorage.setItem("idiomaSeleccionado", idioma);
  }

  load(){
    let idioma = "";
    if (localStorage.getItem("idiomaSeleccionado")) {
      console.log("LOCAL STORAGE SII TIENE IDIOMA")
      idioma = localStorage.getItem("idiomaSeleccionado");
    } else {
      console.log("LOCAL STORAGE NOOOO TIENE IDIOMA")
      idioma = this.translateService.getBrowserLang();
      if (idioma != "es" && idioma != "en" && idioma != "pt" && idioma != "fr" && idioma != "it") {
        idioma = "en";
      }
    }
    console.log("idioma", idioma)
    this.setIdioma(idioma);
  }

}
