import { Component, OnInit } from '@angular/core';
import { ComunicarPropiedadesService } from '../servicios/comunicar-propiedades.service';
import { AuthService } from '../servicios/auth.service';
import { GestionArchivosService } from '../servicios/gestion-archivos.service';

@Component({
  selector: 'app-consultaclus',
  templateUrl: './consultaclus.component.html',
  styleUrls: ['../app.component.css']
})
export class ConsultaclusComponent implements OnInit {

  constructor(private comunicarPropiedadesService: ComunicarPropiedadesService,
              private gestionArchivosService: GestionArchivosService,
              private authService: AuthService) { }

  aplicaciones = [];
  aplicaClus: String = "";
  listaArchivosCluster = [];
  graficoGeneral: any;
  grafico0: string = "";
  graficoCirculo: string = "";
  listaArchivosInform: any;
  confClus: any;
  numClusters = [];
  numClustSeleccionado: Number = undefined;
  marginalXCluster: any;
  tablaCluster: any;
  listClusterVSCoherencia: any;

  ngOnInit() {

    this.verModal(0);
    this.comunicarPropiedadesService.opcionElegida = "6";
    this.comunicarPropiedadesService.aplicaClus = "";
    this.aplicaClus = this.comunicarPropiedadesService.aplicaClus;
    this.comunicarPropiedadesService.listaArchivosCluster = [];
    this.listaArchivosCluster = this.comunicarPropiedadesService.listaArchivosCluster;
    this.aplicaciones = [];

    Promise.resolve().then(async () => {
      try {
        const appLogin = this.authService.getAppUserLogged();
        const aplicacion = `${appLogin.name}`;
        const {username} = this.authService.getUserLogged();
        this.aplicaciones = await this.gestionArchivosService.obtenerModelosAplicacionUsuarioCluster(aplicacion, username);
        this.salirverModal(0);
        // this.configJSON = JSON.parse(this.configText);
          } catch (e) {
            this.salirverModal(0);
            console.log(e);
      }
    });

  }

  verModal(modal: number) {
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[modal].style.display="block";
  }

  salirverModal(modal: number) {
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[modal].style.display="none";
  }

  aplicacionFunc(valor: String) {

    this.verModal(0);
    this.aplicaClus = valor;
    this.comunicarPropiedadesService.aplicaClus = this.aplicaClus;
    

    Promise.resolve().then(async () => {
      try {
        const appLogin = this.authService.getAppUserLogged();
        const aplicacion = `${appLogin.name}`;
        const {username} = this.authService.getUserLogged();
        this.listaArchivosCluster = await this.gestionArchivosService.obtenerGraficosCluster(aplicacion,username, this.aplicaClus);
        this.listaArchivosInform = await this.gestionArchivosService.obtenerInformes(aplicacion,username, this.aplicaClus);
        this.listClusterVSCoherencia = this.listaArchivosInform[2];
        this.graficoGeneral = this.listaArchivosCluster.find(elemento => elemento.name.indexOf("Clusters") > -1)
        this.grafico0 = "data:image/png;base64,"+this.graficoGeneral.data;
        this.confClus = await this.gestionArchivosService.obtenerConfiguracionCluster(aplicacion,username, this.aplicaClus);
        this.numClusters = [];
        this.numClusters = Array.from({length: Number(this.confClus.endCluster) - Number(this.confClus.startCluster) + 1}, (_, i) => i + Number(this.confClus.startCluster));
        this.numClustSeleccionado = Number(this.confClus.startCluster);
        this.cambioCluster(this.numClustSeleccionado);
        this.salirverModal(0);
          } catch (e) {
            this.grafico0 = undefined;
            this.graficoCirculo = undefined;
            this.listaArchivosCluster = [];
            this.graficoGeneral = undefined;
            this.grafico0 = "";
            this.graficoCirculo = "";
            this.listaArchivosInform = undefined;
            this.confClus = undefined;
            this.numClusters = [];
            this.numClustSeleccionado = undefined;
            this.marginalXCluster = undefined;
            this.tablaCluster = undefined;
            this.listClusterVSCoherencia = undefined;
            this.salirverModal(0);
            console.log("");
      }
    });
  }

  cambioCluster(i: Number) {

    this.numClustSeleccionado = i;
    this.marginalXCluster = this.listaArchivosInform[0].find(elemento => elemento.length == i);
    this.tablaCluster = this.listaArchivosInform[1].find(elemento => elemento.length == i).map(ele => ele.join(","));
    this.graficoCirculo = "data:image/png;base64,"+this.listaArchivosCluster.find(elemento => elemento.name == this.aplicaClus+"Circulo"+i+".png").data;
    
  }



  descargarArchivo() {

    Promise.resolve().then(async () => {
      try {
        const appLogin = this.authService.getAppUserLogged();
        const aplicacion = `${appLogin.name}`;
        const {username} = this.authService.getUserLogged();
        const downloadExcelCluster: any = await this.gestionArchivosService.obtenerExcelCluster(aplicacion,username, this.aplicaClus);
        var byteCharacters = atob(downloadExcelCluster.data);
        var byteArray = new Uint8Array(byteCharacters.length);

        for (var i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
        }
    
        var blob = new Blob([byteArray], { type: 'application/octet-stream' });
    
        // Paso 2: Crear un enlace de descarga
        var link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = downloadExcelCluster.name;

        link.click();

      } catch (e) {
        console.log(e)
      }
    });

  }

}

