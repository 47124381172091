import { Component, OnInit } from '@angular/core';
import { ExpRegulares } from '../Beans/ExpRegulares.model';
import { IdiomaService } from '../servicios/idioma.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../servicios/auth.service';
import { ComunicarPropiedadesService } from '../servicios/comunicar-propiedades.service';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {

  showMenu:boolean = false;

  constructor(private idiomaService: IdiomaService,
              private translateService: TranslateService,
              private authService: AuthService,
              private comunicaPropiedadesService: ComunicarPropiedadesService) { }

  ngOnInit() {
    let idioma = "";
    if (localStorage.getItem("idiomaSeleccionado")) {
      idioma = localStorage.getItem("idiomaSeleccionado");
    } else {
      idioma = this.translateService.getBrowserLang();
      if (idioma != "es" && idioma != "en" && idioma != "pt" && idioma != "fr" && idioma != "it") {
        idioma = "en";
      }
    }
    this.idiomaService.setIdioma(idioma);

    //Nos suscribimos al showMenu de comunicarPropiedadesService
    this.comunicaPropiedadesService.showMenuSubject.subscribe(val=>{
      this.showMenu = val;
    })
  }

  idioma(idioma: string) {
    this.idiomaService.setIdioma(idioma);
  }

  cambio(){
    console.log("cambio")
  }

}
