import { Component, OnInit } from '@angular/core';
import { ComunicarPropiedadesService } from '../servicios/comunicar-propiedades.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auto-ml',
  templateUrl: './auto-ml.component.html',
  styleUrls: ['../app.component.css']
})
export class AutoMlComponent implements OnInit {

  generacionAuto: String = "";
  dimPob: String = "";
  erroresEntrada = [];
  opErrores: string = "";
  
  constructor(private comunicarPropiedadesService: ComunicarPropiedadesService,
              private translateService: TranslateService,
              private router: Router) { }

  ngOnInit() {
    this.comunicarPropiedadesService.opcionElegida = "2";
    this.generacionAuto = this.comunicarPropiedadesService.generacionAuto;
    this.dimPob = this.comunicarPropiedadesService.dimPob;
  }

  generacion(gen: String) {
    this.generacionAuto = gen;
    this.comunicarPropiedadesService.generacionAuto = this.generacionAuto;
  }

  dimPoblacion(pob: String) {
    this.dimPob = pob;
    this.comunicarPropiedadesService.dimPob = this.dimPob;
  }

  lanzarAutoML() {

    this.erroresEntrada = [];
    this.opErrores = "0";

    var validaEntrada = this.comunicarPropiedadesService.validaEnt();
    
    if (validaEntrada.filter(elemento => elemento.criticidad == 0).length == 0) {
      if (validaEntrada.filter(elemento => elemento.criticidad == 1).length == 0) { // Sin errores ni warning
        this.comunicarPropiedadesService.lanzarGeneracionModelo("1");
        let er = {
          "errDato": this.translateService.instant('procesando'),
          "criticidad": "2"
        }
        validaEntrada.push(er);
      } else { // Con warning
        this.opErrores = "1";
      }
    } else { // Con errores
      this.opErrores = "2";
    }

    this.erroresEntrada = validaEntrada;
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[0].style.display="block";
  
  }

  ejecutarConWar() {

    this.comunicarPropiedadesService.lanzarGeneracionModelo("1");
    this.salirErroresEntrada();
    
  }

  salirErroresEntrada() {
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[0].style.display="none";
  }

  configuracion() {

    this.comunicarPropiedadesService.opConfig = "0";

    // this.router.navigateByUrl("/configuracion/${opcion}");
    this.router.navigateByUrl('/configuracion');
  }

}
