import { Component, OnInit } from '@angular/core';
import { ComunicarPropiedadesService } from '../servicios/comunicar-propiedades.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-clusters',
  templateUrl: './clusters.component.html',
  styleUrls: ['../app.component.css']
})
export class ClustersComponent implements OnInit {

  constructor(private comunicarPropiedadesService: ComunicarPropiedadesService,
              private translateService: TranslateService,
              private router: Router) { }

  iniClus: number = 2;
  finClus: number = 2;
  erroresEntrada = [];
  opErrores: string = "";

  ngOnInit() {

    this.comunicarPropiedadesService.opcionElegida = "5";
    this.iniClus = this.comunicarPropiedadesService.iniClus;
    this.finClus = this.comunicarPropiedadesService.finClus;

  }

  cambioClus() {

    this.iniClus = Number(this.iniClus.toString().replace(',','').replace('.','').replace('e',''))
    if (this.iniClus < 2) {
      this.iniClus = 2;
    }
    if (this.iniClus > 300) {
      this.iniClus = 300;
    }
    
    this.finClus = Number(this.finClus.toString().replace(',','').replace('.','').replace('e',''))
    if (this.finClus < 2) {
      this.finClus = 2;
    }
    if (this.finClus > 300) {
      this.finClus = 300;
    }
    
    if (this.iniClus > this.finClus) {
      this.iniClus = this.finClus
    }

    this.comunicarPropiedadesService.iniClus = this.iniClus;
    this.comunicarPropiedadesService.finClus = this.finClus;

  }

  configuracion() {

    this.comunicarPropiedadesService.opConfig = "0";

    // this.router.navigateByUrl("/configuracion/${opcion}");
    this.router.navigateByUrl('/configuracion');
  }

  lanzarCluster() {

    this.erroresEntrada = [];
    this.opErrores = "0";

    var validaEntrada = this.comunicarPropiedadesService.validaEntClus();
    
    if (validaEntrada.filter(elemento => elemento.criticidad == 0).length == 0) {
      if (validaEntrada.filter(elemento => elemento.criticidad == 1).length == 0) { // Sin errores ni warning
        this.comunicarPropiedadesService.lanzarGeneracionCluster();
        let er = {
          "errDato": this.translateService.instant('procesando'),
          "criticidad": "2"
        }
        validaEntrada.push(er);
      } else { // Con warning
        this.opErrores = "1";
      }
    } else { // Con errores
      this.opErrores = "2";
    }

    this.erroresEntrada = validaEntrada;
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[0].style.display="block";
  
  }

  salirErroresEntrada() {
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[0].style.display="none";
  }

  ejecutarConWar() {

    this.comunicarPropiedadesService.lanzarGeneracionCluster();
    this.salirErroresEntrada();
    
  }

}
