import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../servicios/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.authService.isLogin()){
        const userRole = this.authService.getUserLogged().rol
        if(next.data.role && !next.data.role.includes(userRole)){
          this.router.navigateByUrl("/login");
          return false;
        }else{
          return true;
        }
      }
      this.router.navigateByUrl("/login");
      return false;
  }

}
