import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './principal/principal-routing.module';
import { AppComponent } from './app.component';
import { PrincipalComponent } from './principal/principal.component';
import { MenuComponent } from './menu/menu.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AutoMlComponent } from './auto-ml/auto-ml.component';
import { ComplejosComponent } from './complejos/complejos.component';
import { ConsultaComponent } from './consulta/consulta.component';
import { LoginComponent } from './login/login.component';
import { GestionUsuariosComponent } from './pages/gestion-usuarios/gestion-usuarios.component';
import { LoadingComponent } from './pages/loading/loading.component';
import { ResetearComponent } from './resetear/resetear.component';
import { ClustersComponent } from './clusters/clusters.component';
import { ConsultaclusComponent } from './consultaclus/consultaclus.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PrincipalComponent,
    MenuComponent,
    ConfiguracionComponent,
    AutoMlComponent,
    ComplejosComponent,
    ConsultaComponent,
    LoginComponent,
    GestionUsuariosComponent,
    LoadingComponent,
    ResetearComponent,
    ClustersComponent,
    ConsultaclusComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
