import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const ARCHIVO_CONFIGURACION_LEER = `${environment.rutaPrediccion}/configRead`;
const ARCHIVO_CONFIGURACION_ESCRIBIR = `${environment.rutaPrediccion}/configWrite`;
const ARCHIVO_IDIOMA = `${environment.rutaPrediccion}/getwords/`;
const MODELOS_APLICACION_USUARIO = `${environment.rutaPrediccion}/getUserModels`;
const MODELOS_APLICACION_USUARIO_CLUSTER = `${environment.rutaPrediccion}/getUserClusterModels`;
const MODELOS_LANZADOS = `${environment.rutaPrediccion}/getUserParams`;
const MODELOS_ESTADISTICAS = `${environment.rutaPrediccion}/getStatistics`;
const MODELOS_VARIABILIDAD = `${environment.rutaPrediccion}/getVari`;
const CONFIG_MODELO = `${environment.rutaPrediccion}/getUserParamsTotal`;
const GRAFICOS_CLUSTER = `${environment.rutaPrediccion}/graphicsRead`;
const INFORMES_CLUSTER = `${environment.rutaPrediccion}/clusterRead`;
const CONFIGURACION_CLUSTER = `${environment.rutaPrediccion}/getUserParamsClusterTotal`;
const EXCEL_CLUSTER = `${environment.rutaPrediccion}/getExcelCluster`;
const EXCEL_ML = `${environment.rutaPrediccion}/getExcel`;
const DOC_TEMPLATE = `${environment.rutaProvisional}/docTemplateInfo`;


@Injectable({
  providedIn: 'root'
})
export class GestionArchivosService {

  constructor(private http: HttpClient) { }

  async obtenerArchivoConfig(aplicacion: String): Promise<string> {

    const parametros = {
      project: aplicacion
    }

    return await this.http.post<string>(ARCHIVO_CONFIGURACION_LEER,parametros).toPromise();

  }

  async guardarArchivoConfig(aplicacion: String, datos: string): Promise<any> {

    const parametros = {
      data: datos,
      project: aplicacion
    }

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    });

    return await this.http.post<any>(ARCHIVO_CONFIGURACION_ESCRIBIR,parametros, { headers } ).toPromise();

  }

  async obtenerArchivoIdioma(idioma: String): Promise<Array<string>> {

    return await this.http.get<Array<string>>(ARCHIVO_IDIOMA+idioma).toPromise();

  }

  async obtenerModelosAplicacionUsuario(aplicacion: String, usuario: String): Promise<[]> {

    const parametros = {
      project: aplicacion,
      user: usuario
    }

    try {
      return await this.http.post<[]>(MODELOS_APLICACION_USUARIO,parametros).toPromise();
    } catch (e) {
      console.log(e.error);
    }

  }

  async obtenerDocTemplate(words: String, 
                          modelo: String, 
                          metodo: String, 
                          estadis: String, 
                          entrada: any, 
                          salida: any,
                          test: String, 
                          countVectorizer: String, 
                          newClassName: String,
                          minRowsClass: String,
                          agrupado: boolean ): Promise<[]> {

    const parametros = {
      words: words,
      model: modelo,
      method: metodo,
      distri: estadis,
      inCol: entrada,
      outCol: salida,
      test: test,
      countVectorizer: countVectorizer, 
      newClassName: newClassName,
      minRowsClass: minRowsClass,
      groupFlag: agrupado 
    }

    return await this.http.post<[]>(DOC_TEMPLATE,parametros).toPromise();

  }

  async obtenerModelosAplicacionUsuarioCluster(aplicacion: String, usuario: String): Promise<[]> {

    const parametros = {
      project: aplicacion,
      user: usuario
    }

    return await this.http.post<[]>(MODELOS_APLICACION_USUARIO_CLUSTER,parametros).toPromise();

  }

  async obtenerConfig(aplicacion: String, usuario: String, modelo: String): Promise<[]> {

    const parametros = {
      modelName: modelo,
      project: aplicacion,
      user: usuario
    }

    return await this.http.post<[]>(CONFIG_MODELO,parametros).toPromise();

  }

  async obtenerModelosLanzados(aplicacion: String, usuario: String, nombre: String): Promise<[]> {

    const parametros = {
      project: aplicacion,
      user: usuario,
      modelName: nombre
    }

    return await this.http.post<[]>(MODELOS_LANZADOS,parametros).toPromise();

  }

  async obtenerEstadisticas(aplicacion: String, usuario: String, nombre: String, modelo: String): Promise<String> {

    const parametros = {
      project: aplicacion,
      user: usuario,
      modelName: nombre,
      data: modelo
    }

    return await this.http.post<String>(MODELOS_ESTADISTICAS,parametros).toPromise();

  }

  async obtenerVari(aplicacion: String, usuario: String, nombre: String): Promise<String> {

    const parametros = {
      project: aplicacion,
      user: usuario,
      modelName: nombre
    }

    return await this.http.post<String>(MODELOS_VARIABILIDAD,parametros).toPromise();

  }

  async obtenerGraficosCluster(aplicacion: String, usuario: String, modelo: String): Promise<[]> {

    const parametros = {
      modelName: modelo,
      project: aplicacion,
      user: usuario
    }

    return await this.http.post<[]>(GRAFICOS_CLUSTER,parametros).toPromise();

  }

  async obtenerInformes(aplicacion: String, usuario: String, modelo: String): Promise<[]> {

    const parametros = {
      modelName: modelo,
      project: aplicacion,
      user: usuario
    }

    return await this.http.post<[]>(INFORMES_CLUSTER,parametros).toPromise();

  }
  
  async obtenerConfiguracionCluster(aplicacion: String, usuario: String, modelo: String): Promise<[]> {

    const parametros = {
      modelName: modelo,
      project: aplicacion,
      user: usuario
    }

    return await this.http.post<[]>(CONFIGURACION_CLUSTER,parametros).toPromise();

  }

  async obtenerExcelCluster(aplicacion: String, usuario: String, modelo: String): Promise<[]> {

    const parametros = {
      modelName: modelo,
      project: aplicacion,
      user: usuario
    }

    return await this.http.post<[]>(EXCEL_CLUSTER,parametros).toPromise();

  }

  async obtenerExcel(aplicacion: String, usuario: String, modelo: String): Promise<[]> {

    const parametros = {
      modelName: modelo,
      project: aplicacion,
      user: usuario
    }

    return await this.http.post<[]>(EXCEL_ML,parametros).toPromise();

  }

}
