import { Component, OnInit } from '@angular/core';
import { GestionArchivosService } from '../servicios/gestion-archivos.service';
import { ComunicarPropiedadesService } from '../servicios/comunicar-propiedades.service';
import { AuthService } from '../servicios/auth.service';

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['../app.component.css']
})
export class ConsultaComponent implements OnInit {

  aplicaciones = [];
  aplica: String = "";
  modelosLanz: any;
  estadisticaDatos: String = "";
  estadisticaVari: String = "";
  pestMar: String = "";
  pestMar2: String = "";
  esperando: boolean = false;
  datosConfiguracion: any;
  inColumnName = [];
  outColumnName = [];
  entradaPrediccion = [];
  entradaPrediccion2 = [];
  inColEntrada = [];
  outColSalida = [];
  salidaLlamada = null;
  modeloSel = "";

  constructor(private comunicarPropiedadesService: ComunicarPropiedadesService,
              private gestionArchivosService: GestionArchivosService,
              private authService: AuthService) { }

  ngOnInit() {

    this.verModal(0);
    this.comunicarPropiedadesService.opcionElegida = "0";
    this.aplica = this.comunicarPropiedadesService.aplica;
    this.cambioPest("bot1");
    
    Promise.resolve().then(async () => {
      try {
        const appLogin = this.authService.getAppUserLogged();
        const aplicacion = `${appLogin.name}`;
        const {username} = this.authService.getUserLogged();
        this.aplicaciones = await this.gestionArchivosService.obtenerModelosAplicacionUsuario(aplicacion, username);
        this.salirverModal(0);
        // this.configJSON = JSON.parse(this.configText);
          } catch (e) {
            this.salirverModal(0);
            console.log(e);
      }
    });

    if (this.aplica!="") {
      this.aplicacionFunc(this.aplica);
    }

  }

  verModal(modal: number) {
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[modal].style.display="block";
  }

  salirverModal(modal: number) {
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[modal].style.display="none";
  }

  aplicacionFunc(valor: String) {
    this.aplica = valor;
    this.comunicarPropiedadesService.aplica = this.aplica;
    this.salidaLlamada = null;
    

    Promise.resolve().then(async () => {
      try {
        const appLogin = this.authService.getAppUserLogged();
        const aplicacion = `${appLogin.name}`;
        const {username} = this.authService.getUserLogged();
        this.modelosLanz = await this.gestionArchivosService.obtenerModelosLanzados(aplicacion,username, this.aplica);
        this.inColEntrada = this.modelosLanz.inColumnName.split(",");
        this.outColSalida = this.modelosLanz.outColumnName.split(",");
        this.entradaPrediccion = Array.from({ length: this.inColEntrada.length }, () => "");
        this.entradaPrediccion2 = [];
        this.cambioPest(this.pestMar);
        this.cambioPest2(this.pestMar2);
        // this.configJSON = JSON.parse(this.configText);
          } catch (e) {
            console.log("");
      }
    });
  }
/*
  onInputChange(event: Event, index: number) {
    // Actualizar el valor en el arreglo entradaPrediccion
    this.entradaPrediccion[index] = (event.target as HTMLTextAreaElement).value;
  }*/

  changeEntrada(index: number, event: Event) {
    this.entradaPrediccion[index] = (event.target as HTMLInputElement).value;
    /*(document.getElementById("ent" + index) as HTMLInputElement).value = (event.target as HTMLInputElement).value;
    let entradaPrediccion2=Array.from(this.entradaPrediccion);
    entradaPrediccion2[index] = (document.getElementById("ent" + index) as HTMLInputElement).value;
    this.entradaPrediccion=Array.from(entradaPrediccion2);*/
    //this.entradaPrediccion[index] = (document.getElementById("ent" + index) as HTMLInputElement).value;
    // document.getElementById("ent"+index).value = "4"
    // this.entradaPrediccion[index] = (event.target as HTMLTextAreaElement).value;
    /*this.entradaPrediccion[index] = (event.target as HTMLInputElement).value;
    let copiaArray = Array.from(this.entradaPrediccion);
    this.entradaPrediccion = [];
    this.entradaPrediccion = copiaArray;*/
    /*this.entradaPrediccion[index] = (event.target as HTMLInputElement).value;
    (document.getElementById("ent" + index) as HTMLInputElement).value = (event.target as HTMLInputElement).value;*/
    console.log("");
  }

  cambioPest(pest: String) {

    let actPest = document.getElementById(pest.toString());
    this.salidaLlamada = null;

    let bot1 = document.getElementById("bot1");
    let bot2 = document.getElementById("bot2");
    let bot3 = document.getElementById("bot3");
    let bot4 = document.getElementById("bot4");
    let bot5 = document.getElementById("bot5");
    let bot6 = document.getElementById("bot6");
    let bot7 = document.getElementById("bot7");
    let bot8 = document.getElementById("bot8");
    let bot9 = document.getElementById("bot9");
    let bot10 = document.getElementById("bot10");
    let bot11 = document.getElementById("bot11");

    bot1.classList.remove("active");
    bot2.classList.remove("active");
    bot3.classList.remove("active");
    bot4.classList.remove("active");
    bot5.classList.remove("active");
    bot6.classList.remove("active");
    bot7.classList.remove("active");
    bot8.classList.remove("active");
    bot9.classList.remove("active");
    bot10.classList.remove("active");
    bot11.classList.remove("active");

    actPest.classList.add("active");

    this.pestMar = pest;
    this.estadisticaDatos = "";
    this.estadisticaVari = "";

    Promise.resolve().then(async () => {
      try {
        const appLogin = this.authService.getAppUserLogged();
        const aplicacion = `${appLogin.name}`;
        const {username} = this.authService.getUserLogged();
        this.estadisticaVari = await this.gestionArchivosService.obtenerVari(aplicacion, username, this.aplica);
        // this.configJSON = JSON.parse(this.configText);
        console.log("");
          } catch (e) {
            console.log(e);
      }
    });

    let modeloSeleccionado = "";
    let modeloSeleccionadoFlag = false;

    if (this.modelosLanz != undefined) {

      switch (pest) {
        case "bot1":
          modeloSeleccionado = "auto";
          modeloSeleccionadoFlag = this.modelosLanz.automl;
          break;
        case "bot2":
          modeloSeleccionado = "RFC";
          modeloSeleccionadoFlag = this.modelosLanz.randomForest;
          break;
        case "bot3":
          modeloSeleccionado = "Logistic";
          modeloSeleccionadoFlag = this.modelosLanz.logistic;
          break;
        case "bot4":
          modeloSeleccionado = "NB";
          modeloSeleccionadoFlag = this.modelosLanz.nb;
          break;
        case "bot5":
          modeloSeleccionado = "SVC";
          modeloSeleccionadoFlag = this.modelosLanz.svc;
          break;
        case "bot6":
          modeloSeleccionado = "KNeighbors";
          modeloSeleccionadoFlag = this.modelosLanz.kneighbors;
          break;
        case "bot7":
          modeloSeleccionado = "RNN";
          modeloSeleccionadoFlag = this.modelosLanz.rnn;
          break;
        case "bot8":
          modeloSeleccionado = "Conv";
          modeloSeleccionadoFlag = this.modelosLanz.conv;
          break;
        case "bot9":
          modeloSeleccionado = "LSTM";
          modeloSeleccionadoFlag = this.modelosLanz.lstm;
          break;
        case "bot10":
          modeloSeleccionado = "Adaboost";
          modeloSeleccionadoFlag = this.modelosLanz.adaboost;
          break;
        case "bot11":
          modeloSeleccionado = "GradientBoosting";
          modeloSeleccionadoFlag = this.modelosLanz.gradientBoosting;
          break;
      }
      this.modeloSel = modeloSeleccionado;
      this.buscarEstadisticas(modeloSeleccionado,modeloSeleccionadoFlag);
      this.cambioPest2("bot12");

    }
    
  }

  cambioPest2 (pest: String) {

    let actPest = document.getElementById(pest.toString());
    let bot12 = document.getElementById("bot12");
    let bot13 = document.getElementById("bot13");

    if (actPest!=null) {

      bot12.classList.remove("active");
      bot13.classList.remove("active");
      actPest.classList.add("active");

    }

    this.pestMar2 = pest;

  }

  buscarEstadisticas(modeloSeleccionado,modeloSeleccionadoFlag) {

    if (this.modelosLanz != undefined && modeloSeleccionadoFlag) {
      this.esperando = true;
      Promise.resolve().then(async () => {
        try {
          const appLogin = this.authService.getAppUserLogged();
          const aplicacion = `${appLogin.name}`;
          const {username} = this.authService.getUserLogged();
          this.estadisticaDatos = await this.gestionArchivosService.obtenerEstadisticas(aplicacion, username, this.aplica, modeloSeleccionado);
          this.esperando = false;
          // this.configJSON = JSON.parse(this.configText);
            } catch (e) {
              console.log("");
        }
      });

    }
  }

  consultaConf() 
  {

    this.aplica = this.comunicarPropiedadesService.aplica;
    
    Promise.resolve().then(async () => {
      try {
        const appLogin = this.authService.getAppUserLogged();
        const aplicacion = `${appLogin.name}`;
        const {username} = this.authService.getUserLogged();
        this.datosConfiguracion = await this.gestionArchivosService.obtenerConfig(aplicacion, username, this.aplica);
        this.inColumnName = this.datosConfiguracion.inColumnName.split(",");
        this.outColumnName = this.datosConfiguracion.outColumnName.split(",");
        this.verModal(1);
        
      } catch (e) {
          console.log(e);
      }
    });

  }
  descargarArchivo() {

    Promise.resolve().then(async () => {
      try {
        const appLogin = this.authService.getAppUserLogged();
        const aplicacion = `${appLogin.name}`;
        const {username} = this.authService.getUserLogged();
        const downloadExcel: any = await this.gestionArchivosService.obtenerExcel(aplicacion,username, this.aplica);
        var byteCharacters = atob(downloadExcel.data);
        var byteArray = new Uint8Array(byteCharacters.length);

        for (var i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
        }
    
        var blob = new Blob([byteArray], { type: 'application/octet-stream' });
    
        // Paso 2: Crear un enlace de descarga
        var link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = downloadExcel.name;

        link.click();

      } catch (e) {
        console.log(e)
      }
    });

  }

  pruebaOK() {

    const appLogin = this.authService.getAppUserLogged();
    const aplicacion = `${appLogin.name}`;
    const {username} = this.authService.getUserLogged();

    this.pestMar



    let envio = {
      "generals": {
        "modelName": this.aplica,
        "project": aplicacion,
        "user": username},
      "data": [this.entradaPrediccion2.join(" ")]}

      envio["automl"] = false;  
      envio["randomForest"] = false;
      envio["logistic"] = false;
      envio["nb"] = false;
      envio["svc"] = false;
      envio["kneighbors"] = false;
      envio["adaboost"] = false;
      envio["gradientBoosting"] = false;    
      envio["rnn"] = false;
      envio["conv"] = false;
      envio["lstm"] = false;

    switch (this.pestMar) {
      case "bot1":
        envio["automl"] = true;
        break;
      case "bot2":
        envio["randomForest"] = true;
        break;
      case "bot3":
        envio["logistic"] = true;
        break;
      case "bot4":
        envio["nb"] = true;
        break;
      case "bot5":
        envio["svc"] = true;
        break;
      case "bot6":
        envio["kneighbors"] = true;
        break;
      case "bot7":
        envio["rnn"] = true;
        break;
      case "bot8":
        envio["conv"] = true;
        break;
      case "bot9":
        envio["lstm"] = true;
        break;
      case "bot10":
        envio["adaboost"] = true;
        break;
      case "bot11":
        envio["gradientBoosting"] = true;
        break;
    }
  
    // envio["typeProcessGPU"] = true;

    Promise.resolve().then(async () => {
      try {
        // cambios en produccion     ---  proyecto prueba debe se el nombre del servicio / cliente / proyecto para el que se este
        // modelando vendrá de la ventana donde se logea el usuario para un determinado proyecto.
        this.salidaLlamada = await this.comunicarPropiedadesService.predecirModelo(envio);
        console.log();
        // this.configJSON = JSON.parse(this.configText);
          } catch (e) {
            console.log(e.error);
      }
    });

  };

  exportarWord() {

    this.verModal(0);

    Promise.resolve().then(async () => {
      try {
        
        const appLogin = this.authService.getAppUserLogged();
        const aplicacion = `${appLogin.name}`;
        const {username} = this.authService.getUserLogged();
        this.datosConfiguracion = await this.gestionArchivosService.obtenerConfig(aplicacion, username, this.aplica);
        let inColEntrada = this.datosConfiguracion.inColumnName.split(",");
        let outColSalida = this.datosConfiguracion.outColumnName.split(",");
        // let modelosLanz: any = await this.gestionArchivosService.obtenerModelosLanzados(aplicacion,username, this.aplica);
        // let inColEntrada = modelosLanz.inColumnName.split(",");
        // let outColSalida = modelosLanz.outColumnName.split(",");
        const downloadWord: any = await this.gestionArchivosService.obtenerDocTemplate(this.estadisticaDatos, 
                                                                                      this.aplica, 
                                                                                      this.modeloSel, 
                                                                                      this.estadisticaVari, 
                                                                                      inColEntrada, 
                                                                                      outColSalida, 
                                                                                      this.datosConfiguracion.test, 
                                                                                      this.datosConfiguracion.words.countVectorizer, 
                                                                                      this.datosConfiguracion.categoryClearing.newClassName,
                                                                                      this.datosConfiguracion.categoryClearing.minRowsClass,
                                                                                      this.datosConfiguracion.categoryClearing.groupClassFlag);
        var byteCharacters = atob(downloadWord.data);
        var byteArray = new Uint8Array(byteCharacters.length);
        console.log(this.datosConfiguracion)
        for (var i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
        }
        var blob = await new Blob([byteArray], { type: 'application/octet-stream' });
    
        try {
          const binaryString = await String.fromCharCode.apply(null, byteArray);
        } catch (e) {
          this.salirverModal(0);
          console.log(e.error);
        }

        // Paso 2: Crear un enlace de descarga
        var link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = downloadWord.name;

        link.click();

        this.salirverModal(0);

        } catch (e) {
            this.salirverModal(0);
            console.log(e.error);
        }
    });

  }

}
