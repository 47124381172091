import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import {md5} from 'md5';
import { AppInfo, Login, NewUser, UserLogged, UserTableInfo } from '../interfaces/login';
import { ComunicarPropiedadesService } from './comunicar-propiedades.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private API_BASE: String = environment.backLogin;

  private isLogged: boolean = false;
  private userLogged: UserLogged;
  private appList: AppInfo[];
  constructor(private http: HttpClient,
    private router: Router) {
  }

  logout(){
    this.isLogged=false;
    this.userLogged = null;
    // this.appList = [];
    //localStorage.setItem('ROLE', '');
    this.router.navigateByUrl("/login")
  }
  async login(user: Login): Promise<any> {
    // console.log("async login ENTRO");

    const data = await this.http.post(this.API_BASE + "/login", user).toPromise<any>();
    // console.log("data: ", data)
    if (data.length == 1) {
      //Hay datos login
      const ul = { ...user, rol: data[0].rol };
      //localStorage.setItem('ROLE', ul.rol);
      //this.userLogged = data;
      // console.log("guardando....", ul)
      this.loginUser(ul);
      // console.log("getUserLogged", this.getUserLogged());
      this.router.navigateByUrl("/")
      return true;
    } else {
      //resp.msgError=data.error;
      this.logout();
    }
    return false;
  }

  loginUser(data: UserLogged){
    // console.log("**loginUser data:",data)
    if(!!data){
      this.userLogged = data;
      this.isLogged = true;
    }else{
      this.userLogged = null;
      this.isLogged = false;
    }
  }

  isLogin(){
    return this.isLogged
  }
  async loadAppList(): Promise<AppInfo[]>{
    // console.log("1-0 GET APP LIST - inicio");
    const promise = await this.http.get(this.API_BASE + "/app").toPromise<any>();
    // console.log("LOAD1-1 APP appList fin ", promise);
    this.appList = promise;


    // console.log("LOAD1-2 APP appList= ", this.appList)
    return promise;
  }
  async getUsers(filter:any): Promise<any[]>{
    return await this.http.post(this.API_BASE + "/users", filter).toPromise<any>();
  }
  getAppList():AppInfo[]{
    return this.appList;
  }
  getUserLogged(): UserLogged{
    //return this.appList.find(x=>x.idApp == this.userLogged.app);
    if(this.isLogged)
      return this.userLogged;
    else return null;
  }
  getAppUserLogged(): AppInfo{
    if(this.isLogged)
      return this.appList.find(x=>x.idApp == this.userLogged.app);
    else return {
      idApp:-1,
      name: undefined
    };
  }


  isAdmin(){
    if(!!this.userLogged){
      return this.userLogged.rol == 'Admin'
    }
    return false;
  }
  async register(data:NewUser): Promise<any>{
    return await this.http.post(this.API_BASE + "/register",
     data,
     {responseType: "text"}).toPromise();
  }
  async deleteUser(idUser:number): Promise<any>{
    const data={
      by: this.getUserLogged(),
      delete: idUser
    }
    return await this.http.post(this.API_BASE + "/deleteUser",
     data,
     {responseType: "text"}).toPromise();
  }
  async updateUser(data:any): Promise<any>{
    return await this.http.put(this.API_BASE + "/updateUser", data, {responseType: "text"}).toPromise();
  }
}
