export class ExpRegular {

    description: String;
    expression: String;
    replaces: String;

}

export class ExpRegulares {

    expRegulares: Array<ExpRegular> = new Array<ExpRegular>();

}