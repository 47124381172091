import { Component, OnInit } from '@angular/core';
import { ExpRegulares } from '../Beans/ExpRegulares.model';
import { IdiomaService } from '../servicios/idioma.service';
import { ComunicarPropiedadesService } from '../servicios/comunicar-propiedades.service';
import { AuthService } from '../servicios/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['../app.component.css']
})
export class MenuComponent implements OnInit {

  constructor(public comunicarPropiedadesService: ComunicarPropiedadesService,
              private idiomaService: IdiomaService,
              private authService: AuthService,
              private router: Router) {

  }

  opcionElegida: String = "1";
  usuarioLog: string = "";
  appLog: string = "";
  rolLog: string = "";
  opConfig: string = "0";

  ngOnInit() {
    this.opcionElegida = this.comunicarPropiedadesService.opcionElegida;
    this.opConfig = this.comunicarPropiedadesService.opConfig;

    this.idiomaService.load();

    this.appLog = this.authService.getAppUserLogged().name;
    this.usuarioLog = this.authService.getUserLogged().username;
    this.rolLog = this.authService.getUserLogged().rol;

  }

  idioma(idioma: string) {
    this.idiomaService.setIdioma(idioma);
  }
  isAdmin(){
    return this.authService.isAdmin()
  }

  logOut() {
    this.resetear();
    this.authService.logout();
  }

  config(opcion) {
    this.opConfig = opcion;
    this.comunicarPropiedadesService.opConfig = this.opConfig;
    if (opcion!="4") {
    // this.router.navigateByUrl("/configuracion/${opcion}");
      this.router.navigateByUrl('/configuracion');
    /*this.router.navigateByUrl('/configuracion/${opcion}', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.router.url]);
    });*/
    } else {
      this.resetear();      
      this.router.navigateByUrl('/resetear');
      /*this.router.navigateByUrl('/configuracion', { skipLocationChange: true }).then(() => {
        this.router.navigate([this.router.url]);
      });*/

      /*this.comunicarPropiedadesService.reloadEvent.subscribe(() => {
        // Lógica de recarga del componente
      });*/

    }
    
  }

  resetear() {
    this.comunicarPropiedadesService.expAAplicar = new ExpRegulares();
      this.comunicarPropiedadesService.configJSON = new ExpRegulares();
      this.comunicarPropiedadesService.nombreModelo = "";
      this.comunicarPropiedadesService.testML = "10%";
      this.comunicarPropiedadesService.palabras = "1000";
      this.comunicarPropiedadesService.fichero = null;
      this.comunicarPropiedadesService.columnaEntrada = [];
      this.comunicarPropiedadesService.columnasSalida = [];
      this.comunicarPropiedadesService.keysNews = [];
      this.comunicarPropiedadesService.listaPalabras = [];
      this.comunicarPropiedadesService.generacionAuto = "5";
      this.comunicarPropiedadesService.dimPob = "50";
      this.comunicarPropiedadesService.rfc = false;
      this.comunicarPropiedadesService.logistica = false;
      this.comunicarPropiedadesService.nb = false;
      this.comunicarPropiedadesService.svc = false;
      this.comunicarPropiedadesService.kne = false;
      this.comunicarPropiedadesService.estimaVal = "20";
      this.comunicarPropiedadesService.estimaAdaVal = "20";
      this.comunicarPropiedadesService.criterioVal = "entropy";
      this.comunicarPropiedadesService.iteraVal = "2000";
      this.comunicarPropiedadesService.kernelVal = "poly";
      this.comunicarPropiedadesService.kernelValSel = "";
      this.comunicarPropiedadesService.probable = false;
      this.comunicarPropiedadesService.vecinosVal = "10";
      this.comunicarPropiedadesService.metricaVal = "minkowski";
      this.comunicarPropiedadesService.base64Excel = null;
      this.comunicarPropiedadesService.salidaLlamada = null;
      this.comunicarPropiedadesService.expregop = true;
      this.comunicarPropiedadesService.rnn = false;
      this.comunicarPropiedadesService.pargenopt = true;
      this.comunicarPropiedadesService.dropoutRNN = "20%";
      this.comunicarPropiedadesService.optimizerVal = "adam";
      this.comunicarPropiedadesService.perdidaVal = "categorical_crossentropy";
      this.comunicarPropiedadesService.metricaCompVal = "accuracy";
      this.comunicarPropiedadesService.loteTMVal = "100";
      this.comunicarPropiedadesService.epocasVal = "10";
      this.comunicarPropiedadesService.capasIn = [];
      this.comunicarPropiedadesService.capasInConv = [];
      this.comunicarPropiedadesService.iniKernelVal = "uniform";
      this.comunicarPropiedadesService.iniKernelConvVal = "uniform";
      this.comunicarPropiedadesService.actiVal = "relu";
      this.comunicarPropiedadesService.outLayer = "32";
      this.comunicarPropiedadesService.rnnConv = false;
      this.comunicarPropiedadesService.pargenoptConv = true;
      this.comunicarPropiedadesService.dropoutRNNConv = "20%";
      this.comunicarPropiedadesService.optimizerConvVal = "adam";
      this.comunicarPropiedadesService.perdidaConvVal = "categorical_crossentropy";
      this.comunicarPropiedadesService.metricaCompConvVal = "accuracy";
      this.comunicarPropiedadesService.loteTMConvVal = "100";
      this.comunicarPropiedadesService.epocasConvVal = "10";
      this.comunicarPropiedadesService.actiConvVal = "relu";
      this.comunicarPropiedadesService.outLayerConv = "32";
      this.comunicarPropiedadesService.filtrosConvVal = "16";
      this.comunicarPropiedadesService.tamKernelConv = "16";
      this.comunicarPropiedadesService.paddingConvVal = "same";
      this.comunicarPropiedadesService.actiConvVal2 = "relu";
      this.comunicarPropiedadesService.embeddingConvVal = "32";
      this.comunicarPropiedadesService.rnnRec = false;
      this.comunicarPropiedadesService.pargenoptRec = true;
      this.comunicarPropiedadesService.dropoutRNNRec = "20%";
      this.comunicarPropiedadesService.optimizerRecVal = "adam";
      this.comunicarPropiedadesService.perdidaRecVal = "categorical_crossentropy";
      this.comunicarPropiedadesService.metricaCompRecVal = "accuracy";
      this.comunicarPropiedadesService.loteTMRecVal = "100";
      this.comunicarPropiedadesService.epocasRecVal = "10";
      this.comunicarPropiedadesService.iniKernelRecVal = "uniform";
      this.comunicarPropiedadesService.actiRecVal = "relu";
      this.comunicarPropiedadesService.outLayerRec = "32";
      this.comunicarPropiedadesService.capaRecVal = "32";
      this.comunicarPropiedadesService.embeddingRecVal = "32";
      this.comunicarPropiedadesService.opcionElegida = "1";
      this.comunicarPropiedadesService.listaIdiomas = [];
      this.comunicarPropiedadesService.adaboost = false;
      this.comunicarPropiedadesService.gradientboosting = false;
      this.comunicarPropiedadesService.aplica = "";
      this.comunicarPropiedadesService.opConfig = "0";

      this.comunicarPropiedadesService.convertir = false;
      this.comunicarPropiedadesService.maymin = "0";
      this.comunicarPropiedadesService.signos = false;
      this.comunicarPropiedadesService.espacios = false;
      this.comunicarPropiedadesService.agrupacion = false;
      this.comunicarPropiedadesService.numMinimo = 1;
      this.comunicarPropiedadesService.nombreClase = "";
      this.comunicarPropiedadesService.iniClus = 2;
      this.comunicarPropiedadesService.finClus = 2;
      this.opConfig = "0";
      this.comunicarPropiedadesService.aplicaClus = "";
      this.comunicarPropiedadesService.listaArchivosCluster = [];
    
  }
}
