import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from '../app.component';
import { ConfiguracionComponent } from '../configuracion/configuracion.component';
import { PrincipalComponent } from './principal.component';
import { AutoMlComponent } from '../auto-ml/auto-ml.component';
import { ComplejosComponent } from '../complejos/complejos.component';
import { ConsultaComponent } from '../consulta/consulta.component';
import { LoginComponent } from '../login/login.component';
import { AuthGuard } from '../guards/auth.guard';
import { GestionUsuariosComponent } from '../pages/gestion-usuarios/gestion-usuarios.component';
import { ResetearComponent } from '../resetear/resetear.component';
import { ClustersComponent } from '../clusters/clusters.component';
import { ConsultaclusComponent } from '../consultaclus/consultaclus.component';


const routes: Routes = [

      { path: '', component: ConfiguracionComponent , canActivate: [AuthGuard]
      },
      { path: 'configuracion', component: ConfiguracionComponent , canActivate: [AuthGuard]
      },
      { path: 'automl', component: AutoMlComponent , canActivate: [AuthGuard]
      },
      { path: 'complejos', component: ComplejosComponent , canActivate: [AuthGuard]
      },
      { path: 'consulta', component: ConsultaComponent , canActivate: [AuthGuard]
      },
      { path: 'login', component: LoginComponent
      },
      { path: 'users', component: GestionUsuariosComponent , canActivate: [AuthGuard] //, data:{ role: ['Admin']}
      },
      { path: 'resetear', component: ResetearComponent , canActivate: [AuthGuard] //, data:{ role: ['Admin']}
      },
      { path: 'clusters', component: ClustersComponent , canActivate: [AuthGuard] //, data:{ role: ['Admin']}
      },
      { path: 'consultaclus', component: ConsultaclusComponent , canActivate: [AuthGuard] //, data:{ role: ['Admin']}
      }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
