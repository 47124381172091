import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ExpRegulares } from '../Beans/ExpRegulares.model';
import { Palabra } from '../Beans/Palabra.model';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

const ENVIO_GEN_MODELO = `${environment.rutaGeneracion}/train/`;
const ENVIO_CLUSTER = `${environment.rutaGeneracion}/cluster/`;
const PREDECIR_MODELO = `${environment.rutaGeneracion}/getPrediction/`;

@Injectable({
  providedIn: 'root'
})
export class ComunicarPropiedadesService {

  /*expAAplicar = new Subject<ExpRegulares>();
  expAAplicarObs = this.expAAplicar.asObservable();*/

  expAAplicar: ExpRegulares = new ExpRegulares();
  configJSON: ExpRegulares = new ExpRegulares();
  nombreModelo: String="";
  testML: String = "10%";
  palabras: String = "1000";
  fichero: File;
  columnaEntrada = [];
  columnasSalida = [];
  keysNews = [];
  listaPalabras = [];
  generacionAuto: String = "5";
  dimPob: String = "50";
  rfc: boolean = false;
  logistica: boolean = false;
  nb: boolean = false;
  svc: boolean = false;
  kne: boolean = false;
  estimaVal: String = "20";
  estimaAdaVal: String = "20";
  criterioVal: String = "entropy";
  iteraVal: String = "2000";
  kernelVal: String = "poly";
  kernelValSel: String = "";
  probable: boolean = false;
  vecinosVal: String = "10";
  metricaVal: String = "minkowski";
  base64Excel: any = null;
  salidaLlamada = null;
  expregop: boolean = true;
  rnn: boolean = false;
  pargenopt: boolean = true;
  dropoutRNN: String = "20%";
  optimizerVal: String = "adam";
  perdidaVal: String = "categorical_crossentropy";
  metricaCompVal: String = "accuracy";
  loteTMVal: String = "100";
  epocasVal: String = "10";
  capasIn = [];
  capasInConv = [];
  iniKernelVal: String = "uniform";
  iniKernelConvVal: String = "uniform";
  actiVal: String = "relu";
  outLayer: String = "32";
  rnnConv: boolean = false;
  pargenoptConv: boolean = true;
  dropoutRNNConv: String = "20%";
  optimizerConvVal: String = "adam";
  perdidaConvVal: String = "categorical_crossentropy";
  metricaCompConvVal: String = "accuracy";
  loteTMConvVal: String = "100";
  epocasConvVal: String = "10";
  actiConvVal: String = "relu";
  outLayerConv: String = "32";
  filtrosConvVal: String = "16";
  tamKernelConv: String = "16";
  paddingConvVal: String = "same";
  actiConvVal2: String = "relu";
  embeddingConvVal: String = "32";
  rnnRec: boolean = false;
  pargenoptRec: boolean = true;
  dropoutRNNRec: String = "20%";
  optimizerRecVal: String = "adam";
  perdidaRecVal: String = "categorical_crossentropy";
  metricaCompRecVal: String = "accuracy";
  loteTMRecVal: String = "100";
  epocasRecVal: String = "10";
  iniKernelRecVal: String = "uniform";
  actiRecVal: String = "relu";
  outLayerRec: String = "32";
  capaRecVal: String = "32";
  embeddingRecVal: String = "32";
  opcionElegida: String = "1";
  usuarioActivo = null;
  listaIdiomas = [];
  adaboost: boolean = false;
  gradientboosting: boolean = false;
  aplica: String = "";
  opConfig: string = "0"
  convertir: boolean = false;
  maymin: string = "0";
  signos: boolean = false;
  espacios: boolean = false;
  agrupacion: boolean = false;
  numMinimo: number = 1;
  nombreClase: String = "";
  iniClus: number = 2;
  finClus: number = 2;
  aplicaClus: String = "";
  listaArchivosCluster = [];

  public showMenuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private translateService: TranslateService,
              private http: HttpClient,
              private authService: AuthService) { }

  showMenu(showMe: boolean){
    this.showMenuSubject.next(showMe);
  }

  validaEnt() {
    let errorEnt = [];
    if (this.nombreModelo == "") {
      let er = {
        "errDato": this.translateService.instant('errorNomMod'),
        "criticidad": "0"
      }
      errorEnt.push(er);
    }
    if (this.base64Excel == null || this.base64Excel.indexOf("base64,") == -1 || this.base64Excel.split("base64,")[1] == "") {
      let er = {
        "errDato": this.translateService.instant('errorArchivo'),
        "criticidad": "0"
      }
      errorEnt.push(er);
    }
    if (this.columnaEntrada.length == 0) {
      let er = {
        "errDato": this.translateService.instant('errorColEnt'),
        "criticidad": "0"
      }
      errorEnt.push(er);
    }
    if (this.columnasSalida.length == 0) {
      let er = {
        "errDato": this.translateService.instant('errorColSal'),
        "criticidad": "0"
      }
      errorEnt.push(er);
    }
    if (this.rnn && this.capasIn.length == 0) {

      let er = {
        "errDato": this.translateService.instant('noCapasRNN'),
        "criticidad": "0"
      }
      errorEnt.push(er);

    }
    if (this.rnnConv && this.capasInConv.length == 0) {

      let er = {
        "errDato": this.translateService.instant('noCapasRNN'),
        "criticidad": "0"
      }
      errorEnt.push(er);

    }
    
    if (this.agrupacion && this.nombreClase.trim() == "") {

      let er = {
        "errDato": this.translateService.instant('nombreClaseVacio'),
        "criticidad": "0"
      }
      errorEnt.push(er);

    }

    if (this.expAAplicar.expRegulares.length == 0) {
      let er = {
        "errDato": this.translateService.instant('avisoExpReg'),
        "criticidad": "1"
      }
      errorEnt.push(er);
    }
    if (this.listaPalabras.length == 0) {
      let er = {
        "errDato": this.translateService.instant('avisoPalabras'),
        "criticidad": "1"
      }
      errorEnt.push(er);
    }

    return errorEnt;
  }

  validaEntClus() {
    let errorEnt = [];
    if (this.nombreModelo == "") {
      let er = {
        "errDato": this.translateService.instant('errorNomMod'),
        "criticidad": "0"
      }
      errorEnt.push(er);
    }
    if (this.base64Excel == null || this.base64Excel.indexOf("base64,") == -1 || this.base64Excel.split("base64,")[1] == "") {
      let er = {
        "errDato": this.translateService.instant('errorArchivo'),
        "criticidad": "0"
      }
      errorEnt.push(er);
    }
    if (this.columnaEntrada.length == 0) {
      let er = {
        "errDato": this.translateService.instant('errorColEnt'),
        "criticidad": "0"
      }
      errorEnt.push(er);
    }
    
    if (this.finClus - this.iniClus > 50) {

      let er = {
        "errDato": this.translateService.instant('masDe50'),
        "criticidad": "0"
      }
      errorEnt.push(er);

    }

    if (this.listaPalabras.length == 0) {
      let er = {
        "errDato": this.translateService.instant('avisoPalabras'),
        "criticidad": "1"
      }
      errorEnt.push(er);
    }

    return errorEnt;
  }

  lanzarGeneracionModelo (tipoAnalisis: String) {
    let testNum = (parseInt(this.testML.replace("%",""))/100).toString();
    // console.log(this.comunicarPropiedadesService.base64Excel);
      /*this.comunicarPropiedadesService.columnaEntrada
      this.comunicarPropiedadesService.columnasSalida;*/
    let reg = this.expAAplicar.expRegulares.map(elemento => {
      return {
        "expression": elemento.expression,
        "replaces": elemento.replaces
      }
    })
    const appLogin = this.authService.getAppUserLogged();
    const aplicacion = `${appLogin.name}`;
    const {username} = this.authService.getUserLogged();
    
    let envio = {
        "generals": {
          "modelName": this.nombreModelo,
          "data": this.base64Excel.split("base64,")[1],
          "project": aplicacion,
          "user": username},
        "inColumn": this.columnaEntrada.map(elemento => elemento.indice).join(","),
        "outColumns": this.columnasSalida.map(elemento => elemento.indice).join(","),
        "cleaning": {
          "regExp": reg
          },
        "words":
          {
              "dictionary": this.listaPalabras.map(elemento => elemento.palabra),
              "countVectorizer": this.palabras
          },
        "test": testNum,
        "categoryClearing": {
          "lowUppFlag": this.convertir,
          "lowUppValue": (!this.convertir) ? "0" : this.maymin,
          "onlyCharNumber": this.signos,
          "noSpaces": this.espacios,
          "groupClassFlag": this.agrupacion,
          "minRowsClass": this.numMinimo.toString(),
          "newClassName": this.nombreClase}
      };
    if (tipoAnalisis == "0") {
      envio = this.modeloComplejo(envio);
    } else {
      envio = this.modeloAutoML(envio);
    }

    console.log(envio);
    let er = {
      "errDato": this.translateService.instant('procesando'),
      "criticidad": "2"
    }


    Promise.resolve().then(async () => {
      try {
        // cambios en produccion     ---  proyecto prueba debe se el nombre del servicio / cliente / proyecto para el que se este
        // modelando vendrá de la ventana donde se logea el usuario para un determinado proyecto.
        this.salidaLlamada = await this.enviarModelo(envio);
        console.log();
        // this.configJSON = JSON.parse(this.configText);
          } catch (e) {
            console.log(e.error);
      }
    });

  }

  async enviarModelo(datos): Promise<any> {

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    });

    return await this.http.post<any>(ENVIO_GEN_MODELO,datos, { headers } ).toPromise();

  }

  async predecirModelo(datos): Promise<any> {

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    });

    return await this.http.post<any>(PREDECIR_MODELO,datos, { headers } ).toPromise();

  }

  async enviarCluster(datos): Promise<any> {

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    });

    return await this.http.post<any>(ENVIO_CLUSTER,datos, { headers } ).toPromise();

  }

  modeloComplejo(envio) {

    envio["automl"] = false,
    envio["randomForest"] = this.rfc;
    if (this.rfc) {
      envio["randomForestParams"] = {
        "estimators": this.estimaVal,
        "criterion": this.criterioVal
      };
    }
    envio["logistic"] = this.logistica;
    if (this.logistica) {
      envio["logisticParams"] = {
        "maxIter": this.iteraVal
      }
    }
    envio["nb"] = this.nb;
    envio["svc"] = this.svc;
    if (this.svc) {
      envio["svcParams"] = {
        "kernel": this.kernelVal,
        "probability": this.probable
      }
    }
    envio["kneighbors"] = this.kne;
    if (this.kne) {
      envio["kneighborsParams"] = {
        "nNeighbors": this.vecinosVal,
        "metric": this.metricaVal
      }
    }

    ///
    /// El siguiente codigo tendrá que modificarse cuando introduzcamos los metodos adaboost y gradientBoosting
    /// en principio pasamos el flag a false para que no falle.
    ///
    envio["adaboost"] = this.adaboost;
    if (this.adaboost) {
      envio["adaboostParams"] = {
        "estimators": this.estimaAdaVal
      };
    }
    envio["gradientBoosting"] = this.gradientboosting;    
    ///
    ///
    ///

    envio["rnn"] = this.rnn;
    if (this.rnn) {
      let dropNum = (parseInt(this.dropoutRNN.replace("%",""))/100).toString();
      envio["rnnParams"] = {
        "layers": this.capasIn,
        "dropout": dropNum,
        "comp": {
          "optimizer": this.optimizerVal,
          "loss": this.perdidaVal,
          "metrics": this.metricaCompVal
        },
        "fit": {
          "batchSize": this.loteTMVal,
          "epochs": this.epocasVal
        }
      }
    }

    envio["conv"] = this.rnnConv;
    if (this.rnnConv) {
      let dropNum = (parseInt(this.dropoutRNNConv.replace("%",""))/100).toString();
      envio["convParams"] = {
        "layers": this.capasInConv,
        "dropout": dropNum,
        "comp": {
          "optimizer": this.optimizerConvVal,
          "loss": this.perdidaConvVal,
          "metrics": this.metricaCompConvVal
        },
        "fit": {
          "batchSize": this.loteTMConvVal,
          "epochs": this.epocasConvVal
        },
        "convolLayer": {
            "filters": this.filtrosConvVal,
            "kernelSize": this.tamKernelConv,
            "padding": this.paddingConvVal,
            "activation": this.actiConvVal2
        },
        "embeddingLayer":   {
          "output": this.embeddingConvVal
        }
      }
    }

    envio["lstm"] = this.rnnRec;
    if (this.rnnRec) {
      let dropNum = (parseInt(this.dropoutRNNRec.replace("%",""))/100).toString();
      envio["lstmParams"] = {
        "layer": {
          "kernelInitializer": this.iniKernelRecVal,
          "activation": this.actiRecVal,
          "output": this.outLayerRec
        },
        "dropout": dropNum,
        "comp": {
          "optimizer": this.optimizerRecVal,
          "loss": this.perdidaRecVal,
          "metrics": this.metricaCompRecVal
        },
        "fit": {
          "batchSize": this.loteTMRecVal,
          "epochs": this.epocasRecVal
        },
        "lstmLayer":    {
          "output": this.capaRecVal
        },
        "embeddingLayer":   {
          "output": this.embeddingRecVal
        }
      }
    }
    //envio["typeProcessGPU"] = true;
    return envio;

  }

  modeloAutoML(envio) {

    envio["automl"] = true,
    envio["automlParams"] = {
      "generations": this.generacionAuto,
      "populationSize": this.dimPob
    }

    envio["randomForest"] = false;
    envio["logistic"] = false;
    envio["nb"] = false;
    envio["svc"] = false;
    envio["kneighbors"] = false;
    envio["adaboost"] = false;
    envio["gradientBoosting"] = false;

    envio["conv"] = false;
    envio["lstm"] = false;
    envio["rnn"] = false;
    // envio["typeProcessGPU"] = true;
    
    return envio;

  }

  lanzarGeneracionCluster () {
    
    const appLogin = this.authService.getAppUserLogged();
    const aplicacion = `${appLogin.name}`;
    const {username} = this.authService.getUserLogged();
    
    let envio = {
        "generals": {
          "modelName": this.nombreModelo,
          "data": this.base64Excel.split("base64,")[1],
          "project": aplicacion,
          "user": username},
        "words":
        {
            "dictionary": this.listaPalabras.map(elemento => elemento.palabra),
            "countVectorizer": this.palabras
        },
        "inColumn": this.columnaEntrada.map(elemento => elemento.indice).join(","),
        "startCluster": this.iniClus.toString(),
        "endCluster": this.finClus.toString()
    };
    
    Promise.resolve().then(async () => {
      try {
        // cambios en produccion     ---  proyecto prueba debe se el nombre del servicio / cliente / proyecto para el que se este
        // modelando vendrá de la ventana donde se logea el usuario para un determinado proyecto.
        this.salidaLlamada = await this.enviarCluster(envio);
        console.log();
        // this.configJSON = JSON.parse(this.configText);
          } catch (e) {
            console.log(e.error);
      }
    });

  }

}
